<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker @change="onChange" :value="createDate" :showTime="{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
              }" format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate">
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <jeepay-text-up :placeholder="'代付订单号'" :msg="searchData.paymentId" v-model="searchData.paymentId" />
            <jeepay-text-up :placeholder="'商户订单号'" :msg="searchData.mchOrderNo" v-model="searchData.mchOrderNo" />
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.appId" placeholder="应用" default-value="" show-search
                optionFilterProp="label" :allowClear="true">
                <!-- <a-select-option value="">全部</a-select-option> -->
                <a-select-option v-for="(item) in appList" :key="item.appId" :value="item.appId"
                  :label="item.appName">{{ item.appName }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.state" placeholder="代付状态" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">订单生成</a-select-option>
                <a-select-option value="1">支付中</a-select-option>
                <a-select-option value="2">支付成功</a-select-option>
                <a-select-option value="3">支付失败</a-select-option>
                <a-select-option value="4">已撤销</a-select-option>
                <a-select-option value="5">已退款</a-select-option>
                <a-select-option value="6">订单关闭</a-select-option>
              </a-select>
            </a-form-item>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryFunc" :loading="btnLoading">搜索</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="reset">重置</a-button>
              <a-button style="margin-left: 8px" type="primary" icon="search" @click="exportfunc">导出</a-button>
            </span>
          </div>
        </a-form>
        <!--统计信息-->
        <div style="margin-bottom:20px">
          <a-button type="primary" @click="showStatisticsClick">
            <a-icon type="bar-chart" /> {{ showStatistics ? '关闭统计' : '显示统计' }}
          </a-button>
          <div class="statistics-view" v-if="showStatistics" :searchData="searchData">
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>代付金额</span>
                <span class="amount-view">{{ paymentOrderInfo.amount | curreny}}<span class="text-view">元</span></span>
              </div>
            </div>
            <a-divider type="vertical" style="height: 90px; background-color: #e8e8e8" />
            <div style="flex: 1;justify-content: center;align-items: center;">
              <div class="statistics-item-view">
                <span>代付订单数</span>
                <span class="amount-black-view">{{ paymentOrderInfo.count }}<span class="text-view">笔</span></span>
                <!-- <span style="margin-top: 10px;">0笔<a-button type="link" style="padding:5px"
                    @click="detail">明细</a-button></span> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 列表渲染 -->
      <JeepayTable @btnLoadClose="btnLoading = false" ref="infoTable" :initData="true"
        :reqTableDataFunc="reqTableDataFunc" :tableColumns="tableColumns" :searchData="searchData" rowKey="paymentId"
        :scrollX="1350">
        <template slot="paymentAmountSlot" slot-scope="{record}"><b>￥{{ record.amount / 100 }}</b></template>
        <!-- 自定义插槽 -->
        <template slot="stateSlot" slot-scope="{record}">
          <a-tag :key="record.state"
            :color="record.state === 0 ? 'blue' : record.state === 1 ? 'orange' : record.state === 2 ? 'green' : 'volcano'">
            {{ record.state === 0 ? '订单生成' : record.state === 1 ? '支付中' : record.state === 2 ? '支付成功' : record.state ===
              3 ? '支付失败' : record.state === 4 ? '已撤销' : record.state === 5 ? '已退款' :'订单关闭' }}
          </a-tag>
        </template>
        <template slot="appName" slot-scope="{record}">{{ getAppName(record.appId) }}</template>
        <template slot="typeSlot" slot-scope="{record}">
          {{ record.orderType === 1 ? 'pds代付' : '商户手动代付' }}
        </template>
        <template slot="opSlot" slot-scope="{record}"> <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button type="link" v-if="$access('ENT_PAYMENT_ORDER_VIEW')"
              @click="detailFunc(record.paymentId)">详情</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>

    <!-- 订单详情 页面组件  -->
    <PaymentOrderDetail ref="paymentOrderDetail" />

  </page-header-wrapper>
</template>
<script>
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import PaymentOrderDetail from './PaymentOrderDetail'
import { API_URL_PAYMENT_ORDER_LIST, API_URL_MCH_APP, API_URL_PAYMENT_ORDER_COUNT, req } from '@/api/manage'
import moment from 'moment'
import appId from '@/views/Utils/appId'
import request from '@/http/request'
import { getNowStartDate, getNowEndDate } from '@/utils/util'
// eslint-disable-next-line no-unused-vars
const tableColumns = [
  { key: 'amount', dataIndex: 'amount', title: '代付金额', width: 110, customRender: (text) => '￥' + (text / 100).toFixed(2) },
  { title: '代付订单号', dataIndex: 'paymentId', width: 180 },
  { title: '商户订单号', dataIndex: 'mchOrderNo', width: 130 },
  { title: '应用名称', scopedSlots: { customRender: 'appName' }, width: 100 },
  { title: '银行卡号', dataIndex: 'cardId', width: 130 },
  { title: '姓名', dataIndex: 'cardName', width: 100 },
  { title: '身份证号', dataIndex: 'certId', width: 130 },
  { title: '手机号', dataIndex: 'mobile', width: 130 },
  { title: '类型', scopedSlots: { customRender: 'typeSlot' }, width: 120 },
  { title: '状态', scopedSlots: { customRender: 'stateSlot' }, width: 100 },
  { title: '创建日期', dataIndex: 'createdAt', width: 150 },
  { title: '操作', width: '100px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]

export default {
  name: 'IsvListPage',
  mixins: [appId],
  components: { JeepayTable, JeepayTableColumns, JeepayTextUp, PaymentOrderDetail },
  data() {
    return {
      showStatistics: false,
      btnLoading: false,
      tableColumns: tableColumns,
      searchData: {
        createdStart: getNowStartDate(), // 选择开始时间
        createdEnd: getNowEndDate(), // 选择结束时间
      },
      createdStart: '', // 选择开始时间
      createdEnd: '', // 选择结束时间
      appList: [],
      paymentOrderInfo: {},
      createDate:[getNowStartDate(),getNowEndDate()]
    }
  },
  mounted() {
    const that = this
    req.list(API_URL_MCH_APP, { 'pageSize': -1 }).then(res => { // 支付方式下拉列表
      that.appList = res.records
    })
    this.reqPaymentOrderCount()
  },
  methods: {
    //关闭显示统计
    showStatisticsClick() {
      this.showStatistics = !this.showStatistics
    },
    queryFunc() {
      this.btnLoading = true
      this.$refs.infoTable.refTable(true)
      this.reqPaymentOrderCount()
    },
    // 请求table接口数据
    reqTableDataFunc: (params) => {
      return req.list(API_URL_PAYMENT_ORDER_LIST, params)
    },
    searchFunc: function () { // 点击【查询】按钮点击事件
      this.$refs.infoTable.refTable(true)
    },
    // 请求统计接口数据
    reqPaymentOrderCount() {
      var params = {
        createdStart: this.searchData.createdStart,
        createdEnd: this.searchData.createdEnd,
        appId: this.searchData.appId,
        state: this.searchData.state,
        paymentId: this.searchData.paymentId,
        mchOrderNo: this.searchData.mchOrderNo
      };
      req.list(API_URL_PAYMENT_ORDER_COUNT, params).then((res) => {
        this.paymentOrderInfo = res
      })
    },
    detailFunc: function (recordId) {
      this.$refs.paymentOrderDetail.show(recordId)
    },
    moment,
    onChange(date, dateString) {
      this.createDate = dateString;
      this.searchData.createdStart = dateString[0] // 开始时间
      this.searchData.createdEnd = dateString[1] // 结束时间
    },
    disabledDate(current) { // 今日之后日期不可选
      return current && current > moment().endOf('day')
    },
    exportfunc() {
      request.request({
        url: '/mch/paymentOrders/export',
        method: 'post',
        data: this.searchData,
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        const blob = new Blob([res])
        link.href = URL.createObjectURL(blob)
        link.download = '代付订单文件下载.xlsx' // 下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((err) => {
        const link = document.createElement('a')
        const blob = new Blob([err], { type: 'application/vnd.ms-excel' })
        link.href = URL.createObjectURL(blob)
        link.download = '代付订单文件下载.xlsx' // 下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    reset() {
      this.searchData = {
        createdStart: getNowStartDate(),
        createdEnd: getNowEndDate()
      }
      this.createDate = [getNowStartDate(), getNowEndDate()]
      this.$nextTick(() => { this.$refs.infoTable.refTable(true) })
    }
  }
}
</script>
<style scoped lang="less">
.statistics-view {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  margin-top: 20px;
  padding: 30px 0px 30px 0px;

}

.statistics-item-view {
  flex-direction: column;
  display: flex;
  text-align: start;
  align-items: center;
}

.amount-view {
  font-size: 20px;
  color: #5485f8;
  font-weight: bold;
  margin-top: 10px;
}

.amount-black-view {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-top: 10px;
}

.amount-h-view {
  font-size: 20px;
  color: #dbaf0e;
  font-weight: bold;
  margin-top: 10px;
}

.text-view {
  font-size: 14px;
  font-weight: normal;
}

.mx-text {
  font-size: 14px;
  font-weight: normal;
  color: #55a2ff;
  padding-left: 10px;
}
</style>
